import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [

	{//登录
		path: '/loginView',
		name: 'loginView',
		component: () => import('@/views/login/loginView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//首页
		path: '/',
		name: 'index',
		component: () => import('@/views/index/index'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//关于我们
		path: '/AboutView',
		name: 'AboutView',
		component: () => import('@/views/about/AboutView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//专卖店
		path: '/shopView',
		name: 'shopView',
		component: () => import('@/views/shop/shopView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//联系我们
		path: '/contactView',
		name: 'contactView',
		component: () => import('@/views/contact/contactView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//列表
		path: '/commodityListView',
		name: 'commodityListView',
		component: () => import('@/views/commodityList/commodityListView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//列表
		path: '/commoditySmallListView',
		name: 'commoditySmallListView',
		component: () => import('@/views/commodityList/commoditySmallListView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//列表
		path: '/goodstmListView',
		name: 'goodstmListView',
		component: () => import('@/views/commodityList/goodstmListView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//列表
		path: '/goodsSortListView',
		name: 'goodsSortListView',
		component: () => import('@/views/commodityList/goodsSortListView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//详情
		path: '/productDetailsView',
		name: 'productDetailsView',
		component: () => import('@/views/productDetails/productDetailsView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//购物袋
		path: '/shoppingBagView',
		name: 'shoppingBagView',
		component: () => import('@/views/shoppingBag/shoppingBagView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//购物袋-结算
		path: '/settlementView',
		name: 'settlementView',
		component: () => import('@/views/settlement/settlementView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//收银台 
		path: '/cashierView',
		name: 'cashierView',
		component: () => import('@/views/cashier/cashierView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//订单详情
		path: '/orderDetailsView',
		name: 'orderDetailsView',
		component: () => import('@/views/orderDetails/orderDetailsView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},

	{//我的信息
		path: '/myAccountView',
		name: 'myAccountView',
		component: () => import('@/views/myAccount/myAccountView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//我的订单
		path: '/myOrderView',
		name: 'myOrderView',
		component: () => import('@/views/myOrder/myOrderView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//我的愿望单
		path: '/myWishlistView',
		name: 'myWishlistView',
		component: () => import('@/views/myWishlist/myWishlistView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//我的最近浏览
		path: '/myBrowsingView',
		name: 'myBrowsingView',
		component: () => import('@/views/myBrowsing/myBrowsingView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//我的钱包
		path: '/myWalletView',
		name: 'myWalletView',
		component: () => import('@/views/myWallet/myWalletView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//退换货品
		path: '/returnsView',
		name: 'returnsView',
		component: () => import('@/views/returns/returnsView'),
		meta: { title: 'D8D帝八帝 - 有趣的灵魂，精湛的工艺' }
	},
	{//造型列表
		path: '/modelListView',
		name: 'modelListView',
		component: () => import('@/views/model/listView'),
		meta: { title: '造型 - D8D帝八帝' }
	},
	{//插画列表
		path: '/illustrationListView',
		name: 'illustrationListView',
		component: () => import('@/views/illustration/listView'),
		meta: { title: '插画 - D8D帝八帝' }
	},
	{//资讯列表
		path: '/informationListView',
		name: 'informationListView',
		component: () => import('@/views/information/listView'),
		meta: { title: '资讯 - D8D帝八帝' }
	},
	{//资讯详情
		path: '/informationDetailsView',
		name: 'informationDetailsView',
		component: () => import('@/views/information/detailsView'),
		meta: { title: '资讯详情 - D8D帝八帝' }
	},

	//后台*******************************
	{//全年统计
		path: '/admin/sum/sellView',
		name: 'adminSumSellView',
		component: () => import('@/views/admin/sum/sellView'),
		meta: { title: '销售统计 - D8D帝八帝' }
	},
	{//天猫商品列表
		path: '/admin/tmallGoodsView',
		name: 'adminTmallGoodsView',
		component: () => import('@/views/admin/tmallGoods/tmallGoodsView'),
		meta: { title: '天猫商品列表 - D8D帝八帝' }
	},
	{//工作流程汇总
		path: '/admin/workFlow/sumView',
		name: 'adminWorkFlowSumView',
		component: () => import('@/views/admin/workFlow/sumView'),
		meta: { title: '店铺工作流程汇总 - D8D帝八帝' }
	},
	{//客户信息
		path: '/admin/memInfoView',
		name: 'adminMemInfoView',
		component: () => import('@/views/admin/memInfo/memInfoView'),
		meta: { title: '客户信息 - D8D帝八帝' }
	},
	{//商品排序
		path: '/admin/goodsSortView',
		name: 'adminGoodsSortView',
		component: () => import('@/views/admin/goodsSort/goodsSortView'),
		meta: { title: '商品排序 - D8D帝八帝' }
	},
	{//商品排序
		path: '/admin/goodsSortStatusView',
		name: 'adminGoodsSortStatusView',
		component: () => import('@/views/admin/goodsSort/goodsSortStatusView'),
		meta: { title: '商品排序 - D8D帝八帝' }
	},
	{//商品状态
		path: '/admin/goodsStatusView',
		name: 'adminGoodsStatusView',
		component: () => import('@/views/admin/goodsStatus/goodsStatusView'),
		meta: { title: '商品状态 - D8D帝八帝' }
	},
	{//店铺库存
		path: '/admin/shop/goodsStockView',
		name: 'adminShopGoodsStockView',
		component: () => import('@/views/admin/shop/goodsStockView'),
		meta: { title: '店铺库存 - D8D帝八帝' }
	},


	/* D8D后台 --------- 开始 --------- */
	{//店铺库存
		path: '/admin/indexView',
		name: 'adminIndexView',
		component: () => import('@/views/admin/index/indexView'),
		meta: { title: '店铺库存 - D8D后台管理' }
	},
	{//库存
		path: '/admin/goodsListView',
		name: 'adminGoodsListView',
		component: () => import('@/views/admin/goodsList/goodsListView'),
		meta: { title: '店铺库存 - D8D后台管理' }
	},
	{//商品信息列表
		path: '/admin/goods/infoListView',
		name: 'adminGoodsInfoListView',
		component: () => import('@/views/admin/goods/infoList/infoListView'),
		meta: { title: '商品列表 - D8D后台管理' }
	},
	{//独立商品编码添加
		path: '/admin/goods/stockInfoOnly/addView',
		name: 'adminGoodsStockInfoOnlyAddView',
		component: () => import('@/views/admin/goods/stockInfoOnly/addView'),
		meta: { title: '添加商品独立编码 - D8D后台管理' }
	},
	{//独立商品编码添加
		path: '/admin/goods/stockInfoOnly/listView',
		name: 'adminGoodsStockInfoOnlyListView',
		component: () => import('@/views/admin/goods/stockInfoOnly/listView'),
		meta: { title: '商品独立编码列表 - D8D后台管理' }
	},
	{//修改订单
		path: '/admin/order/editView',
		name: 'adminOrderEditView',
		component: () => import('@/views/admin/order/editView'),
		meta: { title: '修改订单 - D8D后台管理' }
	},
	{//订单详情
		path: '/admin/order/detailsView',
		name: 'adminOrderDetailsView',
		component: () => import('@/views/admin/order/detailsView'),
		meta: { title: '订单详情 - D8D后台管理' }
	},
	{//订单列表
		path: '/admin/order/listView',
		name: 'adminOrderListView',
		component: () => import('@/views/admin/order/listView'),
		meta: { title: '订单详情 - D8D后台管理' }
	},
	/* D8D后台 --------- 结束 --------- */

]


const router = createRouter({
	history: createWebHashHistory(),
	// history: createWebHistory(),
	// base: '/pc/',
	// history: createWebHistory(process.env.BASE_URL),
	routes
})


router.beforeEach((to, from, next) => {
	// console.log(to, from, next)
	window.document.title = to.meta.title
	next()
})

export default router
