import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'
import './assets/css/base.css'
import Bottom from './components/Bottom.vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'lib-flexible'
import VueCookies from 'vue-cookies'



createApp(App).use(store).use(router).use(ElementPlus, { locale: zhCn }).use(VueCookies).component('Bottom', Bottom).mount('#app')

