/**
 * 配置数据
 */
export const gConfig = {
  // 本地测试配置
  // url_server: 'http://127.0.0.1:8088/',

  // 测试服务器配置
  // url_server : 'http://192.168.31.157:8090/',

  // 正式服务器配置
  url_server: 'https://www.d8d.cn/',

  // 混合配置
  img_url: 'https://www.d8d.cn', // 图片地址
  // img_url: 'http://127.0.0.1:8088', // 图片地址
}