    export const  gAccount = {
        m_token:null,//
        m_accoun:null,//
		m_goodsIdStr:null,
        set_token : function(token)
        {
            this.m_token = token;
            
            localStorage.setItem("m_token", this.m_token);
        },
        set_accoun : function(accoun)
        {
            this.m_accoun = accoun;
            
            localStorage.setItem("m_accoun", this.m_accoun);
        },
		set_goodsIdStr : function(goodsIdStr)
		{
			
			this.m_goodsIdStr = localStorage.getItem("goodsIdStr");
			let goodsIdStr_arr=this.m_goodsIdStr==null?[]:this.m_goodsIdStr.split(',');
			if(goodsIdStr_arr.indexOf(goodsIdStr)==-1){
				if(goodsIdStr_arr.length<20){
					goodsIdStr_arr.unshift(goodsIdStr)
				}else{
					goodsIdStr_arr.slice(0,-1);
					goodsIdStr_arr.unshift(goodsIdStr)
				}
			}else{
				goodsIdStr_arr.splice(goodsIdStr_arr.indexOf(goodsIdStr),1)
				goodsIdStr_arr.unshift(goodsIdStr)
			}
		    localStorage.setItem("goodsIdStr", goodsIdStr_arr.join(','));
		},
		get_goodsIdStr : function()
		{
		   if (null == this.m_goodsIdStr)
		   {
			   this.m_goodsIdStr = localStorage.getItem("goodsIdStr");
		   }
		   return this.m_goodsIdStr;
		},
	    get_token : function()
	    {
		   if (null == this.m_token)
		   {
			   this.m_token = localStorage.getItem("m_token");
		   }
		   return this.m_token;
	    },
        get_token : function()
        {
            if (null == this.m_token)
            {
                this.m_token = localStorage.getItem("m_token");
            }
            return this.m_token;
        },
        get_accoun : function()
        {
            if (null == this.m_accoun)
            {
                this.m_accoun = localStorage.getItem("m_accoun");
            }
            return this.m_accoun;
        },
		remove_token : function()
        {
			this.m_token=null;
			localStorage.removeItem("m_token")
			
        },
		remove_accoun : function()
		{
			this.m_accoun=null;
			localStorage.removeItem("m_accoun")
		},
		remove_goodsIdStr : function()
		{
			this.m_goodsIdStr=null;
			localStorage.removeItem("goodsIdStr")
		},
		
      
    };