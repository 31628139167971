import axios from 'axios'
import { MessageBox, Message, ElMessage } from 'element-plus'
import { gAccount } from '@/data/Account'
import router from '@/router'
import { gConfig } from "../api/Config";
import { useRouter, useRoute } from 'vue-router'
// 用于创建取消请求的错误对象
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials:true，//跨域请求时发送cookie
	timeout: 100000 // request timeout
})
// 请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前执行某些操作
		config.headers['Token'] = gAccount.get_token()
		return config
	},
	error => {
		// 处理请求错误
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	/**
	 如果您想获取http信息，如标题或状态
	 请返回response=>response
	*/

	/**
	 通过自定义代码确定请求状态
	 这里只是一个例子
	 您还可以通过HTTP状态代码来判断状态
	 */
	response => {
		const res = response.data
		// 如果自定义代码不是200，则判断为错误。
		if (res.code == 605) {
			return false
		}
		if (res.code == 100904 || res.code == 100903 || res.code == 100901) {
			return res
		}
		if (res.code == 1001011) {
			return res
		}
		if (res.code == 100908) {
			return false
		}
		if (res.code == 301) {
			window.location.href = gConfig.img_url + res.data.ToUrl
			return false
		}
		if (res.code == 601) {
			ElMessage({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000
			})
			gAccount.remove_token()
			gAccount.remove_accoun()
			redirectLogin();
			return false
		}
		if (res.code == 100101) {
			ElMessage({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000
			})
			router.push({ path: "/commodityListView" })
			return false
		}
		if (res.code === 200 || res.code === 201) {
			return res
		} else {
			ElMessage({
				message: res.msg || 'Error',
				type: 'error',
				duration: 5 * 1000
			})
			return false
		}
	},
	error => {
		ElMessage({
			message: error.msg,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)
function redirectindex() {
	router.replace({
		path: '/'
	})
}
function redirectLogin() {
	router.replace({
		path: '/loginView',
		query: {
			url: window.location.href
		}
	})
}
export default service
